import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Typography,
  Button,
  Paper,
  TableContainer,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  FormControl,
  FormHelperText,
  Link,
} from "@mui/material";
import rj45cable from "./media/rj45cable.png";
import port2 from "./media/itrackport2.png";
import port3 from "./media/itrackport3.png";
import logo from "./media/ilogo.png";
import logo1 from "./media/logo.png";
import portaddress from "./media/portaddress.png";
import itrackpowercable from "./media/itrackpowercable.png";
import itrackantenna from "./media/itrackantenna.png";
import rj45_example from "./media/rj45_example.jpg";
import sungrowtable from "./media/sungrow wire.png";

import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import UndoIcon from "@mui/icons-material/Undo";

import "./SiteCheck.css";
import SignalBar from "./SignalBar.js";

const preloadImages = (srcArray) => {
  srcArray.forEach((src) => {
    const image = new Image();
    image.src = src;
  });
};

preloadImages([rj45cable, port2, port3, logo, logo1, portaddress, itrackpowercable, itrackantenna, rj45_example, sungrowtable]);

function SiteCheck() {
  // Initialise variables
  const { macAddress } = useParams();
  const [deviceStatus, setDeviceStatus] = useState([]);
  const [canRefresh, setCanRefresh] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formError, setFormError] = useState({});
  const [activeDropdowns, setActiveDropdowns] = useState([]);

  const navigate = useNavigate();
  const statusColor = {
    connected: "#4CAF50",
    disconnected: "#f12b3f",
    low: "#ff811a",
  };

  // Submission form variables
  const [formData, setFormData] = useState({
    streetName: "",
    suburb: "",
    state: "",
    postcode: "",
    name: "",
    mobile: "",
    company: "",
    declaration: false,
  });
  const states = {
    "Australian Capital Territory": "ACT",
    "New South Wales": "NSW",
    "Northern Territory": "NT",
    Queensland: "QLD",
    "South Australia": "SA",
    Tasmania: "TAS",
    Victoria: "VIC",
    "Western Australia": "WA",
  };
  const address = `${formData.streetName}, ${formData.suburb} ${formData.state} ${formData.postcode}`;

  // Navigate to the landing page when the logo is clicked
  const handleLogoClick = () => {
    navigate("/");
  };

  // Handling the dropdown instruction boxes
  const handleDropdownClick = (index) => {
    if (activeDropdowns.includes(index)) {
      setActiveDropdowns(activeDropdowns.filter((item) => item !== index));
    } else {
      setActiveDropdowns([...activeDropdowns, index]);
    }
  };

  // Checks for heartbeat when first opening the site
  useEffect(() => {
    if (canRefresh) {
      fetchData(`https://vpp.diamondgem.com.au:5003/installer_hub/monitoring/${macAddress}`, "GET");
      monitoringCommand(); // Make sure to check monitoring when page loads
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Checks: heartbeat every 15 sec
  // Sends: monitoring command every 35 sec
  useEffect(() => {
    const heartbeatIntervalId = setInterval(checkHeartbeat, 15000);
    const monitoringIntervalId = setInterval(monitoringCommand, 35000);
    return () => {
      clearInterval(heartbeatIntervalId);
      clearInterval(monitoringIntervalId);
    };
  });

  // Get and post the data from vpp, is used for the checkHeartbeat and monitoringCommand functions
  const fetchData = async (url, method) => {
    const currentTime = new Date().toLocaleString();
    console.log(currentTime, "fetch:", macAddress);

    if (method === "POST") {
      setCanRefresh(false);
    }

    try {
      const response = await fetch(url, {
        method, // POST OR GET
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      if (data?.data) {
        const formattedData = {
          heartbeat: data.data.heartbeat,
          signalStrength: data.data.signal_strength,
          signalStrengthAlert: data.data.signal_strength < 11,
          monitoring: data.data.monitoring,
        };
        console.log(`${currentTime} response:`, formattedData);
        setDeviceStatus(formattedData);
      } else {
        console.log(`${currentTime} error:`, data.error);
      }
    } catch (error) {
      console.error(`${currentTime} error fetching data:`, error.message);
    } finally {
      if (method === "POST") {
        setTimeout(() => {
          setCanRefresh(true);
          console.log(new Date().toLocaleString(), "Can refresh now.");
          checkHeartbeat();
        }, 20000);
      }
    }
  };

  // Checks if there is a heartbeat. Also returns monitoring values if any already saved in cache.
  const checkHeartbeat = async () => {
    await fetchData(`https://vpp.diamondgem.com.au:5003/installer_hub/monitoring/${macAddress}`, "GET");
  };

  // Sends a manual monitoring command via mqtt to itrack. Returns monitoring values.
  const monitoringCommand = async () => {
    await fetchData(`https://vpp.diamondgem.com.au:5003/installer_hub/manual_monitoring/${macAddress}`, "POST");
  };

  // Make sure that the form is input correctly
  const validateForm = () => {
    const newErrors = {};
    // Street name is required, must be alphanumeric
    if (!formData.streetName) {
      newErrors.streetName = "Required";
    } else if (!/^[a-zA-Z0-9 .]+$/.test(formData.streetName)) {
      // Allowing dots and dashes
      newErrors.streetName = "Invalid address";
    }
    // Suburb is required, must be alphabetical
    if (!formData.suburb) {
      newErrors.suburb = "Required";
    } else if (!/^[a-zA-Z ]+$/.test(formData.suburb)) {
      newErrors.suburb = "Invalid suburb";
    }
    // Postcode is required, must be 4 numerical digits
    if (!formData.postcode) {
      newErrors.postcode = "Required";
    } else if (!/^\d{4}$/.test(formData.postcode)) {
      newErrors.postcode = "Invalid postcode";
    }
    // Installer name is required, must be alphabetical
    if (!formData.name) {
      newErrors.name = "Required";
    } else if (!/^[a-zA-Z ]+$/.test(formData.name)) {
      newErrors.name = "Invalid name format";
    }
    if (!formData.mobile) {
      newErrors.mobile = "Required";
    } else if (!/^(\d{4} \d{3} \d{3})$/.test(formData.mobile)) {
      newErrors.mobile = "Invalid";
    }
    if (!formData.company) {
      newErrors.company = "Required";
    }
    if (!formData.declaration) {
      newErrors.declaration = "Required";
    }
    return newErrors;
  };

  // Formats the mobile number so that it is in the format 04XX XXX XXX
  const formatMobileNumber = (value) => {
    const numbers = value.replace(/\D/g, "");
    const slicedNumbers = numbers.slice(0, 10);
    let formatted = slicedNumbers;
    if (formatted.length > 4) {
      formatted = formatted.slice(0, 4) + " " + formatted.slice(4);
    }
    if (formatted.length > 8) {
      formatted = formatted.slice(0, 8) + " " + formatted.slice(8);
    }
    return formatted;
  };

  // Handles input changes, formats mobile number, and resets errors
  const handleChange = (e) => {
    let { name, value, checked, type } = e.target;
    if (name === "mobile") {
      value = formatMobileNumber(value);
    }
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    setFormError((prev) => ({ ...prev, [name]: "" }));
  };

  // Sepcifically handle the state change (dropdown box)
  const handleStateChange = (e) => {
    const newStateCode = e.target.value;
    setFormData({
      ...formData,
      state: newStateCode,
    });
  };

  // Handle when user clicks submit (i.e. submit form)
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setFormError(newErrors);
      console.log("Form errors:", newErrors);
    } else {
      console.log("Submit form:", formData);
      saveJobDetails();
    }
  };

  // Function to capitalise each word in a string
  function capitaliseWords(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  // Save details from installer information form
  const saveJobDetails = async () => {
    const url = `https://vpp.diamondgem.com.au:5003/installer_hub/save_job/${macAddress}`;
    const body = JSON.stringify({
      address: capitaliseWords(`${formData.streetName}, ${formData.suburb}`),
      state: formData.state,
      postcode: formData.postcode,
      inverter_brand: "Sungrow",
      inverter_model_no: "SH10RT",
      installer_name: capitaliseWords(formData.name),
      installer_mobile: formData.mobile,
      installer_company: capitaliseWords(formData.company),
    });

    const currentTime = new Date().toLocaleString();
    console.log(currentTime, "saving:", macAddress);
    setCanRefresh(false);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });
      const data = await response.json();
      console.log(`${currentTime} response:`, data);

      // only show to submission form when the POST request is successful
      if (response.ok) {
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error(`${currentTime} error fetching data:`, error.message);
    } finally {
      setCanRefresh(true);
    }
  };

  return (
    <Box
      sx={{
        margin: "0 auto",
        textAlign: "center",
        padding: "25px",
      }}
    >
      {/* LOGO HEADER */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <img src={logo} alt="First Logo" className="login-logo" onClick={handleLogoClick} style={{ cursor: "pointer" }} />
        <img src={logo1} alt="Second Logo" className="login-logo" onClick={handleLogoClick} style={{ cursor: "pointer" }} />
      </Box>

      {/* MAC ADDRESS HEADER AND REFRESH BUTTON */}
      <Paper
        style={{
          margin: "auto",
          textAlign: "center",
          position: "relative",
          fontSize: "0.6rem",
          borderRadius: "3px",
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {/* MAC Address */}
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#414142",
            fontSize: 20,
            textAlign: "left",
            flexGrow: 1,
            paddingLeft: "16px",
          }}
        >
          {macAddress}
        </Typography>

        {/* Refresh Button */}
        <Button
          className={canRefresh ? "refreshButton" : ""}
          variant="contained"
          onClick={() => {
            monitoringCommand();
          }}
          style={{
            padding: "10px",
            borderRadius: "0 3px 3px 0", // top-left, top-right, bottom-right, bottom-left
            flexGrow: 0,
            backgroundColor: canRefresh ? "#8dc346" : "#e0e0e0",
            color: "#fff",
            pointerEvents: canRefresh ? "auto" : "none",
            cursor: canRefresh ? "pointer" : "not-allowed",
            fontSize: "1.5rem",
          }}
          disabled={!canRefresh}
        >
          <RefreshIcon />
        </Button>
      </Paper>

      {/* DEVICE STATUS */}
      <Paper
        display="flex"
        style={{
          margin: "auto",
          textAlign: "center",
          position: "relative",
          fontSize: "0.6rem",
          borderRadius: "3px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        {/* Device Status Title */}
        <TableContainer>
          <Typography
            sx={{
              padding: "6px 16px", // vertical, horizontal
              fontSize: 20,
              fontWeight: "bold",
              color: "white",
              background: "#8dc346",
              borderRadius: "3px 3px 0 0", // top-left, top-right, bottom-right, bottom-left
            }}
          >
            Device Status
          </Typography>

          <Table>
            <TableBody>
              {/* internet Connection row */}
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: 18,
                    borderBottom: "none",
                    color: "#414142",
                    padding: "15px 16px 5px", // top, horizontal, bottom
                  }}
                >
                  Internet Connection
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "12px 16px 5px", // top, horizontal, bottom
                  }}
                >
                  {/* display based on heartbeat */}
                  {deviceStatus.heartbeat ? (
                    <Box display="flex" alignItems="center">
                      <Box sx={{ marginRight: "8px" }}>
                        <SignalBar strength={deviceStatus.signalStrength} />
                      </Box>
                      {deviceStatus.signalStrengthAlert ? (
                        <ErrorOutlineOutlinedIcon fontSize="large" style={{ color: statusColor.low }} />
                      ) : (
                        <CheckCircleOutlineIcon fontSize="large" style={{ color: statusColor.connected }} />
                      )}
                    </Box>
                  ) : (
                    <CancelOutlinedIcon fontSize="large" style={{ color: statusColor.disconnected }} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {/* communication with inverter row */}
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: 18,
                    borderBottom: "none",
                    color: "#414142",
                    padding: "10px 16px 10px", // top, horizontal, bottom
                  }}
                >
                  Communication with Inverter
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "10px 16px 10px", // top, horizontal, bottom
                  }}
                >
                  {/* display based on both heartbeat and monitoring status */}
                  {deviceStatus.heartbeat && deviceStatus.monitoring ? (
                    <CheckCircleOutlineIcon fontSize="large" style={{ color: statusColor.connected }} />
                  ) : (
                    <CancelOutlinedIcon fontSize="large" style={{ color: statusColor.disconnected }} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* INTERNET CONNECTION */}
      {!deviceStatus.heartbeat && (
        <Box className="instructionsBox">
          <Typography
            sx={{
              padding: "20px 5px 0px", // Top, Horizontal, Bottom
              fontWeight: "bold",
              fontSize: 20,
              marginBottom: "-8px",
              color: "#414142",
            }}
          >
            Internet Connection Instructions
          </Typography>
          <Typography
            sx={{
              padding: "0px 10px 0px", // Top, Horizontal, Bottom
              textAlign: "left",
              fontSize: 14,
              marginTop: "0px",
              color: "#414142",
            }}
          >
            To establish SolariTRACK connection to the 4G network, follow the steps below.
          </Typography>

          {/* STEP 1 */}
          <Box sx={{ width: "fit-content" }}>
            <Box className="greenBox" onClick={() => handleDropdownClick(5)}>
              <Typography className="greenBoxText">
                <strong>Step 1: </strong>Attach antenna to the SolariTRACK.
              </Typography>

              {/* Toggle button expand-less and expand-more */}
              {activeDropdowns.includes(5) ? (
                <IconButton aria-label="expand-less" size="small" sx={{ marginLeft: "5px" }} onClick={(e) => handleDropdownClick(5)}>
                  <ExpandLessIcon className="expandButton" />
                </IconButton>
              ) : (
                <IconButton aria-label="expand-more" size="small" sx={{ marginLeft: "5px" }} onClick={(e) => handleDropdownClick(5)}>
                  <ExpandMoreIcon className="expandButton" />
                </IconButton>
              )}
            </Box>
            {/* Toggle images */}
            {activeDropdowns.includes(5) && (
              <Box className="expandBox">
                <Box className="imgContainer">
                  <img src={itrackantenna} alt="itrackantenna" className="itrackImage" />
                  <Typography variant="caption" className="itrackCaption">
                    Insert SolariTRACK antenna
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {/* STEP 2 */}
          <Box sx={{ width: "fit-content" }}>
            <Box className="greenBox" onClick={() => handleDropdownClick(6)}>
              <Typography className="greenBoxText">
                <strong>Step 2: </strong>Connect AC power cable.
              </Typography>

              {/* Toggle button expand-less and expand-more */}
              {activeDropdowns.includes(6) ? (
                <IconButton aria-label="expand-less" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(6)}>
                  <ExpandLessIcon className="expandButton" />
                </IconButton>
              ) : (
                <IconButton aria-label="expand-more" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(6)}>
                  <ExpandMoreIcon className="expandButton" />
                </IconButton>
              )}
            </Box>
            {/* Toggle images */}
            {activeDropdowns.includes(6) && (
              <Box className="expandBox">
                <Typography
                  sx={{
                    padding: "10px 15px 15px", // Top, Horizontal, Bottom
                    textAlign: "left",
                    fontSize: 15,
                    color: "#414142",
                  }}
                >
                  Once connected to power, allow 2-5 minutes for the device to establish a connection to the 4G cellular network.
                </Typography>
                <Box className="imgContainer">
                  <img src={itrackpowercable} alt="itrackpowercable" className="itrackImage" />
                  <Typography variant="caption" className="itrackCaption">
                    Plug in SolariTRACK AC power cable
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {/* TROUBLE SHOOTING */}
          <Box>
            <Box className="greenBox" onClick={() => handleDropdownClick(7)}>
              <Typography className="greenBoxText">
                <strong>If no connection</strong>
              </Typography>

              {/* Toggle button expand-less and expand-more */}
              {activeDropdowns.includes(7) ? (
                <IconButton aria-label="expand-less" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(7)}>
                  <ExpandLessIcon className="expandButton" />
                </IconButton>
              ) : (
                <IconButton aria-label="expand-more" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(7)}>
                  <ExpandMoreIcon className="expandButton" />
                </IconButton>
              )}
            </Box>
            {/* Toggle images */}
            {activeDropdowns.includes(7) && (
              <Box className="expandBox">
                <Typography
                  component="ul" // list type
                  sx={{
                    padding: "10px 15px 10px 32px", // Top, Right, Bottom, Left
                    textAlign: "left",
                    fontSize: 15,
                    listStyleType: "disc",
                    color: "#414142",
                  }}
                >
                  <li>Confirm power through AC power adaptor (using multimeter).</li>
                  <li>If limited 4G: use external antenna or ethernet connection through port 4.</li>
                  <li>Power cycle the SolariTRACK (unplug for 5 secs) then wait 5 minutes for reboot.</li>
                  <li>Contact Diamond Energy 1300 838 009.</li>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {/* INVERTER CONNECTION */}
      {deviceStatus.heartbeat && !deviceStatus.monitoring && (
        <Box className="instructionsBox">
          <Typography
            sx={{
              padding: "20px 5px 0px", // Top, Horizontal, Bottom
              fontWeight: "bold",
              fontSize: 20,
              marginBottom: "-8px",
              color: "#414142",
            }}
          >
            Communication Instructions
          </Typography>
          <Typography
            sx={{
              padding: "0px 10px 0px", // Top, Horizontal, Bottom
              textAlign: "left",
              fontSize: 14,
              marginTop: "0px",
              color: "#414142",
            }}
          >
            To establish Modbus communication between the SolariTRACK and the inverter, follow the steps below.
          </Typography>

          {/* STEP 1 */}
          <Box sx={{ width: "fit-content" }}>
            <Box className="greenBox" onClick={() => handleDropdownClick(1)}>
              <Typography className="greenBoxText">
                <strong>Step 1: </strong> Connect RJ45 cable to inverter.
              </Typography>
              {/* Toggle button expand-less and expand-more */}
              {activeDropdowns.includes(1) ? (
                <IconButton aria-label="expand-less" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(1)}>
                  <ExpandLessIcon className="expandButton" />
                </IconButton>
              ) : (
                <IconButton aria-label="expand-more" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(1)}>
                  <ExpandMoreIcon className="expandButton" />
                </IconButton>
              )}
            </Box>
            {activeDropdowns.includes(1) && (
              <Box className="expandBox">
                <Typography
                  sx={{
                    padding: "10px 15px 15px", // Top, Horizontal, Bottom
                    textAlign: "left",
                    fontSize: 15,
                    color: "#414142",
                  }}
                >
                  Connect RJ45 wires 4 and 5 into the RS485 terminals A1 and B1 respectively.
                </Typography>
                <Box className="imgContainer">
                  <img src={rj45cable} alt="rj45cable" className="itrackImage" />
                  <Typography variant="caption" className="itrackCaption">
                    RJ45 Cable Connection
                  </Typography>
                </Box>
                <Box className="imgContainer">
                  <img src={sungrowtable} alt="sungrowtable" className="itrackImage" />
                  <Typography variant="caption" className="itrackCaption">
                    Sungrow Wiring Guide
                  </Typography>
                </Box>
                <Box className="imgContainer">
                  <img src={rj45_example} alt="rj45_example" className="itrackImage" style={{ borderRadius: "3px" }} />
                  <Typography variant="caption" className="itrackCaption">
                    RS485 Connection Example
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {/* STEP 2 */}
          <Box sx={{ width: "fit-content" }}>
            <Box className="greenBox" onClick={() => handleDropdownClick(2)}>
              <Typography className="greenBoxText">
                <strong>Step 2: </strong>Connect RJ45 to SolariTRACK.
              </Typography>
              {/* Toggle button expand-less and expand-more */}
              {activeDropdowns.includes(2) ? (
                <IconButton aria-label="expand-less" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(2)}>
                  <ExpandLessIcon className="expandButton" />
                </IconButton>
              ) : (
                <IconButton aria-label="expand-more" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(2)}>
                  <ExpandMoreIcon className="expandButton" />
                </IconButton>
              )}
            </Box>

            {activeDropdowns.includes(2) && (
              <Box className="expandBox">
                <Typography
                  sx={{
                    padding: "10px 15px 15px", // Top, Horizontal, Bottom
                    textAlign: "left",
                    fontSize: 15,
                    color: "#414142",
                  }}
                >
                  Connect RJ45 wire into modbus port 2 or 3 of the SolariTRACK.
                </Typography>
                <Box className="imgContainer">
                  <img src={port2} alt="port2" className="itrackImage" />
                  <Typography variant="caption" className="itrackCaption">
                    Modbus Port 2
                  </Typography>
                </Box>
                <Box className="imgContainer">
                  <img src={port3} alt="port3" className="itrackImage" />
                  <Typography variant="caption" className="itrackCaption">
                    Modbus Port 3
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {/* STEP 3 */}
          <Box sx={{ width: "fit-content" }}>
            <Box className="greenBox" onClick={() => handleDropdownClick(3)}>
              <Typography className="greenBoxText">
                <strong>Step 3: </strong>Set port address to 245.
              </Typography>
              {/* Toggle button expand-less and expand-more */}
              {activeDropdowns.includes(3) ? (
                <IconButton aria-label="expand-less" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(3)}>
                  <ExpandLessIcon className="expandButton" />
                </IconButton>
              ) : (
                <IconButton aria-label="expand-more" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(3)}>
                  <ExpandMoreIcon className="expandButton" />
                </IconButton>
              )}
            </Box>
            {/* Toggle images */}
            {activeDropdowns.includes(3) && (
              <Box className="expandBox">
                <Typography
                  sx={{
                    padding: "10px 15px 0px", // Top, Horizontal, Bottom
                    textAlign: "left",
                    fontSize: 15,
                    color: "#414142",
                  }}
                >
                  In the iSolarCloud app, open Settings then Serial Port Parameters.
                </Typography>
                <Typography
                  sx={{
                    padding: "0px 15px 15px", // Top, Horizontal, Bottom
                    textAlign: "left",
                    fontSize: 15,
                    color: "#414142",
                  }}
                >
                  Set Device Address to 245.
                </Typography>

                <Box className="imgContainer">
                  <img src={portaddress} alt="portaddress" className="itrackImage" />
                  <Typography variant="caption" className="itrackCaption">
                    Update Device Address
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {/* STEP 4 */}
          <Box sx={{ width: "fit-content" }}>
            <Box className="greenBox" onClick={() => handleDropdownClick(4)}>
              <Typography className="greenBoxText">
                <strong>Step 4: </strong>Update inverter firmware.
              </Typography>
              {activeDropdowns.includes(4) ? (
                <IconButton aria-label="expand-less" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(4)}>
                  <ExpandLessIcon className="expandButton" />
                </IconButton>
              ) : (
                <IconButton aria-label="expand-more" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(4)}>
                  <ExpandMoreIcon className="expandButton" />
                </IconButton>
              )}
            </Box>
            {activeDropdowns.includes(4) && (
              <Box className="expandBox">
                <Typography
                  sx={{
                    padding: "10px 15px 15px", // Top, Horizontal, Bottom
                    textAlign: "left",
                    fontSize: 15,
                    color: "#414142",
                  }}
                >
                  Go to{" "}
                  <Link href="https://service.sungrowpower.com.au/files/Web_Files/FAQ/Monitoring/GD_201906_All_Local%20Firmware%20Upgrade%20via%20iSolarCloud_V1.0%20(1).pdf">
                    service.sungrowpower.com.au
                  </Link>{" "}
                  for a guide to Local Firmware Upgrade via iSolarCloud.
                </Typography>
              </Box>
            )}
          </Box>

          {/* STEP 5 */}
          <Box sx={{ width: "fit-content" }}>
            <Box className="greenBox" onClick={() => handleDropdownClick(8)}>
              <Typography className="greenBoxText">
                <strong>If no communication response</strong>
              </Typography>
              {/* Toggle button expand-less and expand-more */}
              {activeDropdowns.includes(7) ? (
                <IconButton aria-label="expand-less" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(8)}>
                  <ExpandLessIcon className="expandButton" />
                </IconButton>
              ) : (
                <IconButton aria-label="expand-more" size="small" sx={{ marginLeft: "5px" }} onClick={() => handleDropdownClick(8)}>
                  <ExpandMoreIcon className="expandButton" />
                </IconButton>
              )}
            </Box>
            {/* Toggle images */}
            {activeDropdowns.includes(8) && (
              <Box className="expandBox">
                <Typography
                  component="ul" // list type
                  sx={{
                    padding: "10px 15px 10px 32px", // Top, Right, Bottom, Left
                    textAlign: "left",
                    fontSize: 15,
                    listStyleType: "disc",
                    color: "#414142",
                  }}
                >
                  <li>Power cycle all devices.</li>
                  <li>Contact Diamond Energy 1300 838 009.</li>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {/* SHOW FORM FOR INSTALLER */}
      {deviceStatus.heartbeat && deviceStatus.monitoring && !isSubmitted && (
        <>
          <Typography
            style={{
              padding: "20px 10px 0px", // Top, Horizontal, Bottom
              fontSize: 15,
              textAlign: "left",
              color: "#414142",
            }}
          >
            SolariTRACK is online and operational.
          </Typography>
          <Typography
            style={{
              fontSize: 15,
              textAlign: "left",
              padding: "10px 10px 20px", // Top, Horizontal, Bottom
              paddingBottom: "15px",
              color: "#414142",
            }}
          >
            Please <strong> complete the form </strong> below to confirm that works have been completed.
          </Typography>

          <Paper style={{ padding: 16, margin: "auto" }}>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Grid container spacing={2}>
                {/* Section Title */}
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <label style={{ fontWeight: "bold", color: "#414142" }}>Installation Address</label>
                  </Grid>
                </Grid>

                {/* Street TextField */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Street"
                    name="streetName"
                    value={formData.streetName}
                    onChange={handleChange}
                    error={!!formError.streetName}
                    helperText={formError.streetName}
                    size="small"
                    InputProps={{ style: { fontSize: "0.75rem" } }}
                    InputLabelProps={{ style: { fontSize: "0.75rem" } }}
                  />
                </Grid>

                {/* Suburb TextField */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Suburb"
                    name="suburb"
                    value={formData.suburb}
                    onChange={handleChange}
                    error={!!formError.suburb}
                    helperText={formError.suburb}
                    size="small"
                    InputProps={{
                      style: { fontSize: "0.75rem" },
                    }}
                    InputLabelProps={{ style: { fontSize: "0.75rem" } }}
                  />
                </Grid>

                {/* State dropdown */}
                <Grid item xs={12}>
                  <FormControl fullWidth error={!!formError.state}>
                    <TextField
                      id="outlined-select-native"
                      select
                      value={formData.state}
                      onChange={handleStateChange}
                      label="State"
                      InputProps={{
                        style: { fontSize: "0.75rem" },
                      }}
                      InputLabelProps={{ style: { fontSize: "0.75rem" } }}
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="" style={{ display: "none" }}></option>
                      {Object.entries(states).map(([stateName, stateCode]) => (
                        <option key={stateCode} value={stateCode}>
                          {stateName}
                        </option>
                      ))}
                    </TextField>
                    <FormHelperText>{formError.state}</FormHelperText>
                  </FormControl>
                </Grid>

                {/* Postcode TextField */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Postcode"
                    name="postcode"
                    value={formData.postcode}
                    onChange={handleChange}
                    error={!!formError.postcode}
                    helperText={formError.postcode}
                    size="small"
                    InputProps={{
                      style: { fontSize: "0.75rem" },
                      inputProps: { maxLength: 4, pattern: "[0-9]*" },
                    }}
                    InputLabelProps={{ style: { fontSize: "0.75rem" } }}
                  />
                </Grid>

                {/* Section Title */}
                <Grid item xs={12} style={{ marginTop: "5px" }}>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#414142",
                    }}
                  >
                    Installer Details
                  </label>
                </Grid>

                {/* Installer Name TextField */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!formError.name}
                    helperText={formError.name}
                    size="small"
                    InputProps={{ style: { fontSize: "0.75rem" } }}
                    InputLabelProps={{ style: { fontSize: "0.75rem" } }}
                  />
                </Grid>

                {/* Installer Mobile TextField */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    error={!!formError.mobile}
                    helperText={formError.mobile}
                    size="small"
                    InputProps={{ style: { fontSize: "0.75rem" } }}
                    InputLabelProps={{ style: { fontSize: "0.75rem" } }}
                  />
                </Grid>

                {/* Installer Company TextField */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    error={!!formError.company}
                    helperText={formError.company}
                    size="small"
                    InputProps={{ style: { fontSize: "0.75rem" } }}
                    InputLabelProps={{ style: { fontSize: "0.75rem" } }}
                  />
                </Grid>

                {/* Declaration Checkbox Component */}
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <FormControlLabel
                    control={<Checkbox checked={formData.declaration} onChange={handleChange} name="declaration" color="primary" />}
                    label={
                      <Typography variant="body2" style={{ fontSize: "12px", color: "#414142" }}>
                        I declare that the information provided above is true and correct, and that all onsite works have been completed.
                      </Typography>
                    }
                  />
                  {formError.declaration && (
                    <FormHelperText error>
                      <Typography variant="body2" style={{ fontSize: "12px", color: "#f44336" }}>
                        {formError.declaration}
                      </Typography>
                    </FormHelperText>
                  )}
                </Grid>

                {/* Submit Button Component */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth style={{ backgroundColor: "#8dc346" }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </>
      )}

      {/* FORM SUBMITTED VIEW */}
      {deviceStatus.heartbeat && deviceStatus.monitoring && isSubmitted && (
        <Paper
          style={{
            padding: "15px 8px 40px 8px",
            margin: "auto",
            textAlign: "center",
            position: "relative",
            fontSize: "0.6rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CheckCircleOutline style={{ fontSize: 40, color: "#4CAF50" }} />
          <Typography
            variant="h5"
            style={{
              marginTop: "10px",
              fontSize: "0.9rem",
              fontWeight: "bold",
            }}
          >
            Form Submitted
          </Typography>
          <Typography variant="h5" style={{ marginBottom: "20px", fontSize: "0.75rem" }}>
            SolariTRACK works are complete
          </Typography>
          <Table size="small">
            <TableBody>
              {/* combine the address line */}
              <TableRow>
                <TableCell className="submissionTableCell" component="th" scope="row" sx={{ fontWeight: "bold", borderBottom: "none" }}>
                  Address
                </TableCell>
                <TableCell className="submissionTableCell" sx={{ borderBottom: "none" }}>
                  {capitaliseWords(address)}
                </TableCell>
              </TableRow>

              {/* display installer details */}
              {Object.entries(formData).map(
                ([key, value]) =>
                  ["name", "mobile", "company"].includes(key) && (
                    <TableRow key={key}>
                      <TableCell className="submissionTableCell" component="th" scope="row" sx={{ fontWeight: "bold", borderBottom: "none" }}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </TableCell>
                      <TableCell className="submissionTableCell" sx={{ borderBottom: "none" }}>
                        {capitaliseWords(value)}
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
          <Box style={{ position: "absolute", bottom: 5, left: 5 }}>
            <Button
              onClick={() => setIsSubmitted(false)}
              startIcon={<UndoIcon />}
              style={{
                color: "#F44336",
                fontSize: "0.6rem",
                padding: "2px 4px",
              }}
              size="small"
            >
              Undo
            </Button>
          </Box>
        </Paper>
      )}
      {deviceStatus.heartbeat && deviceStatus.monitoring && isSubmitted && (
        <Typography
          variant="h5"
          sx={{
            position: "absolute",
            bottom: "10px",
            left: 0,
            right: 0,
            fontSize: "0.5rem",
          }}
        >
          You can close this page.
        </Typography>
      )}
    </Box>
  );
}

export default SiteCheck;
